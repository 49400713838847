<template>
  <!-- Informations générales communes à toutes les pages -->
  <form @submit.prevent="onSubmit()">
    <div class="general-information-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <h2>Informations générales</h2>
        </Container>
      </div>

      <Section>
        <Container>
          <Input id="designation" label="Désignation" :required="task.type.uid === 'NOTATION'"/>

          <Input
            id="date_previsionnelle"
            label="Date prévisionnelle"
            type="date"
            class="max-width-xxs"
          />

          <h5 v-if="stade.apiParams?.filters" class="color-gray">OU</h5>

          <SelectExtended
            v-if="stade.apiParams?.filters"
            id="stade_id"
            label="Stade prévisionnel"
            optionKey="id"
            optionValue="designation_calculee"
            apiEndpoint="dictionnaire/stade"
            :apiParams="stade.apiParams"
          />
          <Input id="observations"
            label="Observations"
            type="textarea" />
        </Container>
      </Section>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'protocolRatingCalendar', params: {id: $route.params.id} }" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'GeneralInformationSubview',
  components: {
    Btn,
    Container,
    Section,
    Input,
    SelectExtended,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().nullable(),
      date_previsionnelle: this.yup.date().nullable().transform((c, o) => (o === '' ? null : c)),
      stade_id: this.yup.object().nullable(),
      observations: this.yup.string().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      componentKey: 0,
      essaiId: this.$route.params.id,
      isSubmitting,
      errors,
      onSubmit,
      validationSchema,
      stade: {
        apiParams: {
          sort: 'ordre.ASC',
        },
      },
      schema: {},
    }
  },

  emits: ['update-task'],
  watch: {
    // eslint-disable-next-line func-names
    '$props.task': function () {
      this.setDatas()
    },
  },

  mounted() {
    this.setDatas()
  },

  methods: {
    async setDatas() {
      if (this.task) {
        await this.getProtocol()
        this.schema = await this.getFiche()
        this.formService.setFormValues(this.schema)
      }
    },
    async getProtocol() {
      const response = await this.fetchService.get(`protocole/${this.$route.params.id}`)
      const protocol = await response.data

      const cultures = protocol.cultures.map((item) => item.uid)
      if (cultures.length > 0) {
        this.stade.apiParams = {
          sort: 'ordre.ASC',
          filters: `culture.uid:in(${cultures})`,
        }
      }

      return protocol
    },
    async getFiche() {
      this.emitter.emit('open-loader')

      this.schema = this.formService.populateShema(this.task)

      if (this.task.stade) {
        this.schema.stade_id = {
          key: this.task.stade.id,
          value: `${this.task.stade.code_bbch} - ${this.task.stade.designation_court}`,
        }
      }

      this.schema.date_previsionnelle = this.helperService
        .formatDateForInput(this.task?.date_previsionnelle)

      this.emitter.emit('close-loader')
      return this.schema
    },

    handleSubmit(values) {
      this.emitter.emit('open-loader')
      if (typeof values.designation === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        values.designation = this.fiche.designation
      }
      // eslint-disable-next-line no-param-reassign
      values.stade_id = values?.stade_id?.key ?? null

      // eslint-disable-next-line no-param-reassign
      values.date_realisation = this.helperService.formatDateForApi(
        values?.date_realisation,
      )
      // eslint-disable-next-line no-param-reassign
      values.date_previsionnelle = this.helperService.formatDateForApi(
        values?.date_previsionnelle,
      )

      this.fetchService
        .put(`protocole/${this.essaiId}/evenement/${this.task.id}`, values)
        .then((r) => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'L\'événement a bien été modifié.',
          })
          this.$emit('update-task', r.data)
          this.emitter.emit('close-loader')
        })
        .catch((responseError) => {
          this.formService.handleApiError(responseError)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
